<template>
  <div class="ca-details-container">
    <p>Coming Soon...</p>
  </div>
</template>

<script>
export default {
  name: 'CorporateActionDetails'
}
</script>
<style lang="scss" scoped>
.ca-details-container {
  font-size: 20px;
}
</style>
